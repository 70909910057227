import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import styled from "styled-components"
import CTA from "./cta"

const BottomColorNavStyled = styled.div`
  margin: 0 auto 0 auto;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media (max-width: 1068px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 580px) {
    grid-template-columns: 1fr;
  }

  .box {
    height: 300px;
    position: relative;
    width: 100%;

    &:hover {
      img.static {
        opacity: 0;
      }

      img.ani {
        opacity: 1;
      }
    }
  }

  .box > .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
  }

  img {
    transition: width 500ms, height 500ms;
    max-height: 180px;
  }

  .ani {
    opacity: 0;
  }

  .imageHolder {
    height: 180px;
    width: 100%;
    max-width: 283px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    img {
      position: absolute;
    }
  }

  p {
    margin: 0;
    font-size: 2em;
    text-align: center;
    font-weight: bold;
    letter-spacing: 1px;
  }

  a {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    text-decoration: none;
    color: ${(p) => p.theme.DarkBlue};
  }

  @media (max-width: 768px) {
    img {
      /* width: 280px; */
    }
  }
`

const BottomColorNav = () => {
  const data = useStaticQuery(graphql`
    query {
      color_nav: allWordpressPage(filter: { slug: { eq: "shared-layout" } }) {
        edges {
          node {
            acf {
              colorful_footer {
                background_color
                image {
                  source_url
                }
                animated_image {
                  source_url
                }
                link_text
                link_url
              }
            }
          }
        }
      }
    }
  `)

  const color_nav = data.color_nav.edges[0].node.acf.colorful_footer
  return (
    <BottomColorNavStyled>
      {color_nav.map((c, i) => {
        const {
          link_text,
          link_url,
          image,
          animated_image,
          background_color,
        } = c
        return (
          <div tabIndex="0" className="box" key={i}>
            <Link
              className="inner"
              to={link_url}
              style={{
                backgroundColor: background_color,
              }}
            >
              <div className="imageHolder">
                <img
                  src={image.source_url}
                  className="static"
                  alt={link_text}
                />
                <img
                  src={animated_image.source_url}
                  className="ani"
                  alt={link_text}
                />
              </div>
              <CTA>{link_text}</CTA>
            </Link>
          </div>
        )
      })}
    </BottomColorNavStyled>
  )
}

export default BottomColorNav
