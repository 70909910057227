import React from "react"
import styled from "styled-components"
import Button from "./button"
import Img from "gatsby-image/withIEPolyfill"
import SVG from "./svg"

const HalfHeroStyled = styled.div`
  position: relative;
  height: 330px;
  z-index: 0;
  margin: 93px auto 0 auto;

  .background {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    max-width: 2560px;
    margin: 0 auto;
    top: 0;
    height: 330px;
    position: absolute;
    min-height: 330px;
    width: 100%;
  }

  .gatsby-image-wrapper {
    width: 100%;
    min-height: 330px;
  }

  .heroH1 {
    transition: padding 600ms;
    position: relative;
    font-size: 1em;
    z-index: 1;
    font-weight: 800;
    letter-spacing: 2px;
    height: 330px;
    display: flex;
    align-items: ${(p) => (p.align === "left" ? "flex-start" : "center")};
    justify-content: center;
    flex-flow: column;
    z-index: 2;
    margin: 0 auto;
    max-width: 1400px;
    font-family: "interstate-compressed";
    width: ${(p) => p.width || "100%"};

    @media (max-width: 1450px) {
      padding: 0px 50px 0 50px;
    }

    @media (max-width: 768px) {
      padding: 0px 50px 0 50px;
      margin: 0;
    }
    @media (max-width: 580px) {
      padding: 0px 10px 0 10px;
    }
  }

  .topline {
    color: ${(p) => p.fields.hero_area.top_line_color || p.theme.DarkBlue};
    font-size: 2.4em;
    font-family: "Montserrat";
    margin-left: 4px;
  }

  .headline {
    transition: font-size 600ms;
    color: ${(p) => p.fields.hero_area.headline_color || p.theme.DarkBlue};
    line-height: 0.9em;
    font-size: 9.2em;
    margin-bottom: 20px;
    text-align: center;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0px;

    .topline {
      font-size: 1.6em;
    }

    .background {
      width: 768px;
    }

    .headline {
      font-size: 5em;
    }
  }
`

const HalfHero = ({ width, fields, children, color, align }) => {
  return (
    <HalfHeroStyled width={width} fields={fields} color={color} align={align}>
      <div className="background">
        {fields.hero_area.background ? (
          <Img
            alt={fields.headline}
            imgStyle={{ zIndex: 1 }}
            fluid={fields.hero_area.background.localFile.childImageSharp.fluid}
          />
        ) : (
          ""
        )}
      </div>
      <h1 className="heroH1">
        {fields.hero_area.top_line ? (
          <div
            className="topline"
            tabIndex="0"
            dangerouslySetInnerHTML={{ __html: fields.hero_area.top_line }}
          />
        ) : (
          ""
        )}
        <div
          className="headline"
          dangerouslySetInnerHTML={{ __html: fields.hero_area.headline }}
          tabIndex="0"
        />
        <SVG name="SWave" color={fields.hero_area.wave_color} />
      </h1>
    </HalfHeroStyled>
  )
}

export default HalfHero
