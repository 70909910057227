import React from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import Layout from "../components/layout"
import ResponsiveSpacing from "../components/responsive-spacing"
import { Row, Col } from "../components/rows"
import SEO from "../components/seo"
import Button from "../components/button"
import Center from "../components/center"
import BottomColorNav from "../components/bottom-color-nav"
import Carousel from "../components/carousel"
import Heading from "../components/heading"
import Merch from "../components/merch/merch-item"
import HalfHero from "../components/half-hero"
import { useStaticQuery, graphql } from "gatsby"

const MerchContStyled = styled.div`
  max-width: 1400px;
  margin: 0px auto;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;

  @media (max-width: 768px) {
    margin: 8px;
  }
`

const MerchList = ({ dispatch, page, merch }) => {
  const merchHtml = merch.map((r, index) => {
    const re = r.acf
    if (re.not_for_sale) return ""
    return (
      <Col>
        <Merch {...r} dispatch={dispatch} key={index} index={index} />
      </Col>
    )
  })
  return (
    <MerchContStyled>
      <ResponsiveSpacing>
        <Row width="900px" margin="0px auto">
          {merchHtml}
        </Row>
      </ResponsiveSpacing>
    </MerchContStyled>
  )
}

const ConnectedMerch = connect((state) => state)(MerchList)

const MerchPage = () => {
  const data = useStaticQuery(graphql`
    query {
      page: allWordpressPage(filter: { slug: { eq: "merch-page" } }) {
        edges {
          node {
            title
            acf {
              hero_area {
                headline
                headline_color
                wave_color
                background {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 2560, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
              search_title
              search_description
              share_headline
              share_comment
              share_image {
                source_url
              }
              opening {
                headline
                sub_heading
              }
              more_button_label
              carousel {
                headline
                sub_heading
                button_label
              }
            }
          }
        }
      }
      merch: allWordpressWpMerch {
        edges {
          node {
            id
            wordpress_id
            title
            acf {
              name
              not_for_sale
              name_with_html
              sku
              price
              short_info
              promotion
              option_choices {
                label
                choices
                default_choice
              }
              option_skus {
                labels
                sku
                price
                sold_out
              }
              images {
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 450, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      flavors: allWordpressWpFlavors {
        edges {
          node {
            wordpress_id
            id
            title
            acf {
              flavor {
                name
                carton_image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 450, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                inside_image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 450, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const page = data.page.edges[0].node
  const fields = page.acf
  const merch = data.merch.edges.map((e) => e.node)
  const flavors = data.flavors.edges.map((e) => e.node)
  const {
    search_title,
    share_headline,
    search_description,
    share_comment,
    share_image,
  } = fields
  return (
    <Layout>
      <SEO
        title={search_title}
        description={search_description}
        headline={share_headline}
        share_comment={share_comment}
        image={share_image}
      />
      <HalfHero fields={fields}>{fields.hero_area.headline}</HalfHero>
      <Heading margin="50px 0 100px 0" mobileMargin="50px 0 20px 0">
        <h2>{fields.opening.headline}</h2>
        <h3>{fields.opening.sub_heading}</h3>
      </Heading>
      <ConnectedMerch merch={merch} page={page} />
      <Heading margin="150px 0 50px 0">
        <h2>{fields.carousel.headline}</h2>
        <h3>{fields.carousel.sub_heading}</h3>
      </Heading>
      <Carousel flavors={flavors} />
      <Center margin="0 0 150px 0" mobileMargin="0 0 50px 0">
        <Button to="/flavors">{fields.carousel.button_label}</Button>
      </Center>
      <BottomColorNav />
    </Layout>
  )
}

export default MerchPage
