import styled from "styled-components"

export const Row = styled.div`
  display: grid;
  position: relative;
  z-index: 1;
  grid-template-columns: ${(props) => props.columns || "1fr 1fr"};
  grid-gap: ${(props) => props.gap || "16px"};
  align-items: flex-start;
  max-width: ${(props) => props.width || "1160px"};
  margin: ${(props) => (props.margin ? props.margin : "0 auto")};

  @media (max-width: 768px) {
    margin: ${(props) => props.mobileMargin || "50px 0px"};
    grid-template-columns: ${(props) => props.mobile || "1fr"};
    grid-gap: 0;
  }
`

export const Col = styled.div`
  text-align: ${(props) => props.align};
  @media (max-width: 768px) {
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: column;
  }
`
