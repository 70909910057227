import React from "react"
import styled from "styled-components"
import { Link, navigate } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import Button from "../../components/button"
import Center from "../../components/center"
import PromoSticker from "../promo-sticker"
const postPath = require("../../utils/post-path")

const MerchStyled = styled.div`
  position: relative;
  max-width: 360px;
  width: 100%;
  align-self: center;
  margin: 0 4px;

  @media (max-width: 768px) {
    margin: 0 0 20px 0;
  }

  a {
    text-decoration: none;
  }

  img {
    max-width: 360px;
  }

  .blank {
    width: 360px;
    height: 244px;
    background: grey;
  }

  h4 {
    color: ${(p) => p.theme.Blue};
    font-family: "Montserrat";
    font-size: 1.4em;
    font-weight: bold;
    min-height: 50px;
    text-align: center;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    flex-flow: column;
    text-transform: uppercase;
  }

  .price {
    border-top: 1px solid ${(p) => p.theme.Grey};
    padding: 10px 0 0;
    color: ${(p) => p.theme.DarkBlue};
    font-family: "Montserrat";
    font-size: 2.4em;
    font-weight: bold;
    text-align: center;
    position: relative;
  }

  .infoIcon {
    width: 26px;
    cursor: pointer;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  span {
    color: ${(p) => p.theme.DarkBlue};
  }
`

const Merch = (props) => {
  const { dispatch, acf } = props
  const { name, name_with_html, images, price, sku, promotion } = acf
  const image = images[0].image.localFile.childImageSharp.fluid.src
  const product = {
    name,
    image,
    price,
    sku,
  }

  let doAction
  if (acf.option_choices && acf.option_choices.length > 0) {
    doAction = () => navigate(postPath("/merch/", name))
  } else {
    doAction = () => dispatch({ type: "ADD_TO_CART", product })
  }

  return (
    <MerchStyled>
      <Link to={postPath("/merch/", name)}>
        {images.length >= 1 ? (
          <Img
            alt={name}
            fluid={images[0].image.localFile.childImageSharp.fluid}
          />
        ) : (
          <div className="blank" />
        )}
        <h4
          dangerouslySetInnerHTML={{
            __html: name_with_html,
          }}
        />
        <PromoSticker promotion={promotion} className="topLeftInside" />
      </Link>
      <div className="price">${price}</div>
      <Center margin="10px 0 0 0">
        {promotion === "sold-out" ? (
          <Button icon="+" disabled={true}>
            ADD TO CART
          </Button>
        ) : (
          <Button icon="+" onClick={(e) => doAction()}>
            ADD TO CART
          </Button>
        )}
      </Center>
    </MerchStyled>
  )
}

export default Merch
